
.styled-alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 5px;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

 .switchstyling{
  padding: 0.3rem;
  border: 1px rgb(34, 34, 34) solid;
  border-radius: 0.5rem;
} 
.linkstyles{
  text-decoration: none;
}
.nameinform{
  text-transform: capitalize;
}
.rowheading{
  font-weight: bold;
  color: rgb(46, 46, 46);
  font-size: 13pt;
}
.rowerror{
  background-color: rgb(202, 255, 206);
  padding: 0.5rem;
  color: #a00000;
  font-size: 12pt;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.txtinfo{
  margin-top: -15px !important;
  font-weight: 500;
}
.errorMsg {
  color: #cc0000;
  margin-bottom: 12px;
}
.form-control {
  border: var(--bs-border-width) solid #686868 !important;
  background-color: #f4f5f6 !important;
}
.form-label {
  font-weight: 500;
}
.sucessMsg {
  color: #6B8E23;
  margin-bottom: 10px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
